<template>
    <div class="container">
        <div class="modal"></div>
        <div class="loading-cover">
            <div class="header">
                <div class="header-title">MetaGo智能合同</div>
            </div>
            <div class="center">
                <div class="slide-container">
                    <div class="slide-item" :class="slideIndex===0 ? 'active' : 'hide'">
                        <div class="info-title">智能合同库</div>
                        <div class="info"><strong class="strong">数百份</strong>由<strong class="strong">资深法律专家</strong>所起草的常用协议智能模板，覆盖人力资源、公司经营等各个阶段。方便使用，最小化您的法律风险！</div>
                    </div>
                    <div class="slide-item" :class="slideIndex===1 ? 'active' : 'hide'">
                        <div class="info-title">快速制作您的智能文档</div>
                        <div class="info">仅需<strong class="strong">15分钟</strong>时间，即可将已有Word文件升级为MetGo智能模板，重复使用更准确、快速。</div>
                    </div>
                    <div class="slide-item" :class="slideIndex===2 ? 'active' : 'hide'">
                        <div class="info-title">移动端使用</div>
                        <div class="info">您可以在移动端填写和一键生成所需的文档，也可以分享给他人。</div>
                    </div>
                </div>
                <div class="center-right">
                    <div class="image-center"></div>
                </div>
            </div>
            <div class="footer">
                <div class="dots">
                    <div class="dot" :class="slideIndex===0 ? 'active' : ''"></div>
                    <div class="dot" :class="slideIndex===1 ? 'active' : ''"></div>
                    <div class="dot" :class="slideIndex===2 ? 'active' : ''"></div>
                </div>

                <div class="progress-container">
                    <div class="progress-title">导出中</div>
                    <div class="progress-bar">
                        <div class="foreground"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Repeat from "../assets/js/repeat";

    export default {
        name: "SmartContractGenerateLoadingCover",
        props: {
          timeOver :{
              type: Function,
              required: false,
          }
        },
        data: function() {
            return {
                slideIndex: 0,
            }
        },
        mounted() {
            this.startSlideTimer();
        },
        methods: {
            startTick: function () {
                this.progressTimer = new Repeat(this.updateProgress.bind(this), 1000, false);
                this.progressTimer.start();
            },
            startSlideTimer : function() {
                this.slideTimer = new Repeat(this.updateSlide.bind(this), [7000, 5000, 3000], false);
                this.slideTimer.start();
            },
            updateProgress: function () {
                this.progressCounter += 1;
                if (this.progressCounter >= this.progressStep) {
                    /**
                     * 延迟500ms之后再隐藏弹窗
                     */
                    setTimeout(() => {
                        if (this.timeOver) {
                            this.timeOver();
                        }
                    }, 500);
                }
                return this.progressCounter < this.progressStep;
            },
            updateSlide: function () {
                this.slideIndex = (this.slideIndex + 1) % 3;

                const stop = this.slideIndex >= 2;
                /**
                 * 触发弹窗显示结束结束回调
                 */
                if (stop) {
                    setTimeout(() => {
                        if (this.timeOver) {
                            this.timeOver();
                        }
                    }, 3000 + 100);
                }

                return !stop;
            }
        },
        unmounted() {
            if (this.progressTimer) {
                this.progressTimer.cancel();
            }

            if (this.slideTimer) {
                this.slideTimer.cancel();
            }
        }
    }
</script>

<style lang="scss" scoped>
.container {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
}
.modal {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .7;
}
.loading-cover {
    background-color: #fff;
    border-radius: 3px;
    width: 788px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.header {
    background: center / contain no-repeat url($publicPath + 'static/img/bg_tk.png');
    background-size: 100% 100%;
}
.header-title {
    font-size: 32px;
    color: #fff;
    padding: 20px 40px;
    text-align: left;
}
.center {
    display: flex;
    justify-content: space-between;
    padding: 0 65px;
}
.slide-container {
    max-width: 275px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.slide-item {
    box-sizing: border-box;
    text-align: left;
    transform:translateX(100px);
}
.slide-item.active {
    display: block;
    animation: 1s forwards show;
}
.slide-item.hide {
    display: none;
    animation: 1s forwards hide;
}
.slide-item .info-title {
    padding: 26px 0;
    white-space: nowrap;
    color: #333333;
    font-size: 24px;
    font-weight: 600;
}
.slide-item .info {
    text-align: left;
    color: #565555;
    font-size: 16px;
}
.center-right {
    box-sizing: border-box;
    margin-top: 20px;
}
.center-right .image-center {
    height: 312px;
    width: 350px;
    background:  center / contain no-repeat url($publicPath + "static/img/img_tk.png");
}
.footer {
    padding-bottom: 20px;
}
.dots {
    display: flex;
    padding: 13px 65px;
}
.dots .dot {
    margin-right: 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #f5f5f5;
}
.dot.active {
    background-color: #1c4dc5;
}
.progress-container {
    display: flex;
    padding: 5px 40px;
    justify-content: space-between;
    align-items: center;
}
.progress-title {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
}
.progress-bar {
    background-color: #f5f5f5;
    height: 14px;
    border-radius: 7px;
    width: 100%;
    margin-left: 20px;
    margin-top: 3px;
}
.foreground {
    border-radius: 7px;
    height: 14px;
    background-color: #ff9204;
    width: 0%;
    animation: 15s linear forwards progress;
}
.strong {
    color: #000;
}
@keyframes show{
    to {
        visibility:visible;
        opacity: 1;
        transform: translateX(0)
    }
}
@keyframes hide{
    to {
        visibility:hidden;
        opacity: 0;
        transform: translateX(-100px)
    }
}
@keyframes progress {
    from {
        width: 0;
    }

    to {
        width: 99%;
    }
}
</style>
