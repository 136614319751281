export default class Repeat {
    constructor(func, interval, random) {
        this.func = func;
        this.interval = interval;
        this.intervalIndex = -1;
        this.random = random;
        this.timeID = null;
    }

    randomInterval() {
        if (Array.isArray(this.interval)) {
            this.intervalIndex += 1;
            this.intervalIndex = this.intervalIndex % this.interval.length;
            const currentInterval = this.interval[this.intervalIndex];

            return  this.random ? Math.floor(Math.random() * currentInterval) : currentInterval;
        }

        return this.random ? Math.floor(Math.random() * this.interval) : this.interval;
    }

    start() {
        this.timeID = setTimeout(() => {
            if (this.func()) {
                this.start()
            }
        }, this.randomInterval())
    }

    cancel() {
        if (this.timeID) {
            clearTimeout(this.timeID);
        }
    }
}
